
import { Component, Vue, Mixins, Prop } from "vue-property-decorator";
// api
import { updateMenu, createMenu } from "@/api/menu";
import { Menu } from "@/types/api/menu";
import print from "@/utils/print";

interface Route {
  comment: any;
  name: string;
  path: string;
}

@Component
export default class Table extends Vue {
  @Prop() dialogVisible!: boolean;
  @Prop() form!: Menu;
  @Prop() type!: string;
  @Prop() isChild!: boolean;

  ruleForm: string = "ruleForm";
  // 规则
  rules: any = {
    title: [
      { required: true, message: "请输入菜单标题", trigger: "blur" },
      {
        min: 1,
        max: 20,
        message: "长度在 1 到 10 个字符",
        trigger: "blur",
      },
    ],
    level: [{ required: true, message: "请输入菜单等级", trigger: "blur" }],
    parentId: [
      {
        required: true,
        message: "请输入父级id，如果当前设为父级则填写0",
        trigger: "blur",
      },
    ],
    name: [{ required: true, message: "请输入前端名称", trigger: "blur" }],
    icon: [{ required: true, message: "请输入前端图标", trigger: "blur" }],
    sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
    hidden: [{ required: true, message: "请选择状态", trigger: "change" }],
  };
  routers: Array<Route> = []; // 路由

  mounted() {
    let route: any = this.$router;
    let len = route.options.routes.length;
    this.routers = route.options.routes[len - 1].children.filter(
      (e: Route) => e.name != "首页"
    );
    this.routers.forEach((e: Route) => {
      e.path = e.path.replace("/", "");
    });
  }

  // 关闭对话框
  close() {
    this.$emit("close");
  }
  // 确认提交
  confirm() {
    let ref: any = this.$refs;
    ref[this.ruleForm].validate((valid: any) => {
      // 校验成功
      if (valid) {
        let prom: any;
        if (this.type == "add") {
          prom = createMenu(this.form); // 添加
        } else {
          prom = updateMenu(this.form); // 修改
        }
        prom.then((res: any) => {
          this.$message.success("提交成功");
          this.$emit("getData");
          this.close();
        });
      } else {
        return false;
      }
    });
  }
}
