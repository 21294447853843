
import { Component, Vue, Mixins } from "vue-property-decorator";
// 混入
import Loading from "@/mixins/loading";
import tablePagination from "@/mixins/tablePagination";
// 组件
import Table from "./components/Table.vue";
import Header from "./components/Header.vue";
import Dialog from "./components/Dialog.vue";
//  api
import { Menu } from "@/types/api/menu";
import { getMenuById } from "@/api/menu";
// 工具
import copy from "@/utils/deepCopy";
import print from "@/utils/print";

@Component({
  components: { Table, Header, Dialog },
})
export default class MenuComponent extends Mixins(Loading, tablePagination) {
  dialogVisible: boolean = false;
  initForm: Menu = {
    createTime: "",
    hidden: 0,
    icon: "",
    id: 0,
    level: 0,
    name: "",
    parentId: 0,
    sort: 0,
    title: "",
  };
  form: Menu = this.initForm;
  type: string = "add"; // add:添加 edit:编辑
  isChild: boolean = false; // 是否显示下级
  parentId: number = 0; // 父级ID

  // 添加
  handleAdd() {
    this.type = "add";
    this.form = copy(this.initForm);
    this.form.parentId = this.parentId;
    this.dialogVisible = true;
  }
  // 编辑
  handleEdit(row: Menu) {
    this.showLoading();
    getMenuById({ id: row.id }).then((res: any) => {
      this.type = "edit";
      this.form = res.data;
      this.hideLoading();
      this.dialogVisible = true;
    });
  }
  // 获取数据
  getData() {
    let table: any = this.$refs.table;
    table.getData();
  }
  // 显示下级
  setChild(val: boolean) {
    this.isChild = val;
  }
  // 设置父级id
  setParentId(val: number) {
    this.parentId = val;
  }
  // 返回上级
  backParent() {
    this.isChild = false;
    let table: any = this.$refs.table;
    table.parentId = 0;
    table.getData();
  }
}
