
import { Component, Vue, Mixins } from "vue-property-decorator";
import Loading from "@/mixins/loading";
import tablePagination from "@/mixins/tablePagination";
// api
import { getMenuList, deleteMenu, updateMenu } from "@/api/menu";
import { Menu } from "@/types/api/menu";
import print from "@/utils/print";

@Component
export default class Table extends Mixins(Loading, tablePagination) {
  private tableData: Array<Menu> = [];
  parentId: number = 0;

  mounted() {
    this.getData();
  }

  /**
   * methods
   */
  getData() {
    this.showLoading();
    getMenuList({
      pageSize: this.pageSize,
      pageNum: this.currentPage,
      parentId: this.parentId,
    }).then((res: any) => {
      this.tableData = res.data.list;
      this.total = res.data.total;
      this.hideLoading();
    });
  }
  // 查看下级
  viewChildLevel(row: Menu) {
    if (this.parentId == 0) {
      this.parentId = row.id;
      this.$emit("parentId", row.id);
      this.$emit("setChild", true);
      this.getData();
    } else {
      this.parentId = 0;
      this.$emit("parentId", 0);
      this.$emit("setChild", false);
      this.getData();
    }
  }
  // 删除
  handleDelete(row: Menu) {
    this.$confirm(`是否要删除菜单编号 ${row.id} ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      deleteMenu({
        id: row.id,
      }).then((res: any) => {
        this.getData();
        this.$message.success("删除成功");
      });
    });
  }
}
