import { service } from '@/ajax/request'
import { Id } from '@/types/common'
import { AxiosPromise } from 'axios'
import { Menu, MenuList } from '@/types/api/menu'



// 添加后台菜单
export const createMenu = (params: Menu): AxiosPromise => (
  service({
    url: `/menu/create`,
    method: 'POST',
    params
  })
)

// 修改后台菜单
export const updateMenu = (params: Menu): AxiosPromise => (
  service({
    url: `/menu/update/${params.id}`,
    method: 'POST',
    params
  })
)

// 修改菜单显示状态
export const updateHidden = (params: { id: number, hidden: number }): AxiosPromise => (
  service({
    url: `/menu/updateHidden/${params.id}`,
    method: 'POST',
    params: {
      hidden: params.hidden
    },
    type: 'form'
  })
)

// 根据ID获取菜单详情
export const getMenuById = (params: Id): AxiosPromise => (
  service({
    url: `/menu/${params.id}`,
    method: 'get'
  })
)

// 根据ID删除后台菜单
export const deleteMenu = (params: Id): AxiosPromise => (
  service({
    url: `/menu/delete/${params.id}`,
    method: 'post'
  })
)

// 分页查询后台菜单
export const getMenuList = (params: MenuList): AxiosPromise => (
  service({
    url: `/menu/list/${params.parentId}`,
    method: 'get',
    type: 'form',
    params
  })
)

// 树形结构返回所有菜单列表
export const treeList = (): AxiosPromise => (
  service({
    url: `/menu/treeList`,
    method: 'get',
    type: 'form'
  })
)